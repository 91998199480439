import React, { useEffect, useState } from "react";
import GMACube from "../media/geral/gma_cube.svg";
import PersonsAnimated from "./animations/PersonsAnimated";
import getData from '../utils/data/mainData';
import { quemSomos } from '../utils/data/types';

const QuemSomos: React.FC = () => {
  const [data, setData] = useState<quemSomos|null>(null);

  const loadData = async () => {
    const auxData:quemSomos|any = await getData({ data: 'WHO_WE_ARE' });
    setData(auxData);
  };

  useEffect(() => {
    loadData();
  }, []);

  if(data){

    return (
      <div
        id="id_quemSomos"
        className="row col-12  align-items-start justify-content-center"
      >
        <div className="col-10 col-sm-6 col-md-8 col-lg-4 pt-5">
          <img src={GMACube} className="imgSize" alt="Gma Logo" />
          {/* <GMACube /> */}
        </div>
        <div className="row col-11 col-lg-7 align-items-center justify-content-center justifyText">
          <h3 className="display-3 text-center">{data.label1}</h3>
          <p className="mt-3 fs-6 customFont">
            {data.p1}
          </p>
          <p className="fs-6 customFont">
            {data.p2}
          </p>
          <p className="fs-6 customFont">
            {data.p3}
          </p>
          <p className="fs-6 customFont">
            {data.p4}
          </p>
          <p className="fs-6 customFont">
            {data.p5}
          </p>
          <p className="fs-6 customFont">
            {data.p6}
          </p>
          
        </div>
        <div className="col-11 col-sm-6 col-md-3">
            {/* <img src={BusinessPartner} /> */}
            <PersonsAnimated />
          </div>
      </div>
    );
  }else{
    return (<div></div>)
  }
};

export default QuemSomos;
