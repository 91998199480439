import React, { useEffect, useState } from "react";
import "../css/components/NavBar.css";
import $ from "jquery";
import GMACube from "../media/geral/gma_cube.svg";
import { setLanguage } from "../utils/language/definition";
import getData from '../utils/data/mainData';
import { navbar } from '../utils/data/types';

interface scroll {
  scrollToId: string;
}

const scrollTo = ({ scrollToId }: scroll) => {
  const scroolPosition = $(scrollToId).offset()?.top || 0;
  $("html, body").animate(
    {
      scrollTop: scroolPosition - 80,
    },
    1000
  );
};

const NavBar: React.FC = () => {
  const [data, setData] = useState<navbar|null>(null);

  const loadData = async () => {
    const auxData:navbar|any = await getData({ data: 'NAVBAR' });
    setData(auxData);
  };

  useEffect(() => {
    loadData();
  }, []);

  let navBar = document.getElementById("mainDivNavBar");
  let sticky = navBar?.offsetTop;

  let prevScrollpos = window.pageYOffset;

  const scroolFunction = () => {
    const currentScrollPos = window.pageYOffset;
    const navBarElement = document.getElementById("mainDivNavBar");
    if (sticky && currentScrollPos > sticky - 20) {
      navBar?.classList.add("sticky");
    } else {
      navBar?.classList.remove("sticky");
    }
    if (sticky && currentScrollPos > sticky) {
      if (prevScrollpos > currentScrollPos && navBarElement) {
        navBarElement.style.top = "0";
      } else {
        if (navBarElement) navBarElement.style.top = "-70px";
      }
      prevScrollpos = currentScrollPos;
    } else {
      if (navBarElement) navBarElement.style.top = "";
    }
  };

  useEffect(() => {
    navBar = document.getElementById("mainDivNavBar");
    sticky = navBar?.offsetTop;
    window.onscroll = () => {
      scroolFunction();
    };
  });

  if(data){
    return (
      <nav
        id="mainDivNavBar"
        className="navbar navbar-expand-lg mb-3 navbar-dark positionAbsolute"
      >
        <div className="container backgoundBlack">
          <a
            className="navbar-brand navbarBrand"
            onClick={() => scrollTo({ scrollToId: "mainLogo" })}
          >
            <img
              src={GMACube}
              alt=""
              width="40"
              height="40"
              className="d-inline-block align-text-top"
            />
            Business
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#id_navBar"
            aria-controls="id_navBar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon btn-light"></span>
          </button>
          <div
            className="collapse navbar-collapse align-items-center justify-content-center"
            id="id_navBar"
          >
            <div className="align-items-center justify-content-center">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item m-2">
                  <a
                    onClick={() => scrollTo({ scrollToId: "#inicio_id" })}
                    className="text-decoration-none"
                  >
                    <span id="id_div1" className="btnItem btn text-light">
                      {data.item1}
                    </span>
                  </a>
                </li>
                <li className="nav-item m-2">
                  <a
                    className="text-decoration-none"
                    onClick={() => scrollTo({ scrollToId: "#id_commerce" })}
                  >
                    <span id="id_div2" className="btnItem btn text-light">
                      {data.item2}
                    </span>
                  </a>
                </li>
                <li className="nav-item m-2">
                  <a
                    className="text-decoration-none"
                    onClick={() =>
                      scrollTo({ scrollToId: "#id_empreendimentos" })
                    }
                  >
                    <span id="id_div4" className="btnItem btn text-light">
                      {data.item3}
                    </span>
                  </a>
                </li>
                <li className="nav-item m-2">
                  <a
                    className="text-decoration-none"
                    onClick={() => scrollTo({ scrollToId: "#id_quemSomos" })}
                  >
                    <span id="id_div5" className="btnItem btn text-light">
                      {data.item4}
                    </span>
                  </a>
                </li>
                <li className="nav-item m-2">
                  <a
                    className="text-decoration-none"
                    onClick={() => scrollTo({ scrollToId: "#contatoDiv" })}
                  >
                    <span id="id_div6" className="btnItem btn text-light">
                      {data.item5}
                    </span>
                  </a>
                </li>
                <li className="nav-item m-2">
                  <div className="dropdown ">
                    <button className="btn dropdown-toggle text-light" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                      {data.label1}
                    </button>
                    <ul className="dropdown-menu dropdown-menu-dark backgoundBlack" aria-labelledby="dropdownMenuButton2">
                      <li><a className="dropdown-item navbarBrand" onClick={()=>setLanguage({lang:'ptg'})}>{data.lang1}</a></li>
                      <li><a className="dropdown-item navbarBrand" onClick={()=>setLanguage({lang:'eng'})}>{data.lang2}</a></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }else{
    return(<div/>)
  }
};

export default NavBar;
