import React, { useEffect, useState } from "react";
import "../css/components/Contato.css";
import { Loader } from "@googlemaps/js-api-loader";
import GMABusinessLogo from "../media/geral/gmaBusinessLogo.svg";
import getData from "../utils/data/mainData";
import { contact } from "../utils/data/types";

const Contato: React.FC = () => {
  const [data, setData] = useState<contact | null>(null);

  const loadData = async () => {
    const auxData: contact | any = await getData({ data: "CONTACT" });
    setData(auxData);
  };

  useEffect(() => {
    loadData();
  }, []);

  // const loader = new Loader({
  //   apiKey: "AIzaSyA1XhvVNSxXAdbqVuDBsCsDp_UXy5SP0Ug",
  //   version: "weekly",
  // });

  // useEffect(() => {
  //   loader.load().then(() => {
  //     const map = new google.maps.Map(
  //       document.getElementById("map") as HTMLElement,
  //       {
  //         center: { lat: -15.790852545777186, lng: -47.882646924790706 },
  //         zoom: 15,
  //       }
  //     );
  //     new google.maps.Marker({
  //       position: { lat: -15.790852545777186, lng: -47.882646924790706 },
  //       map,
  //       title: "GMA Business",
  //     });
  //   });
  // }, []);

  if (data) {
    return (
      <div id="contatoDiv" className="overflow-hidden">
        <div className="container pb-5">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-lg-6">
              <div>
                <h3 className="display-3 text-white">{data.label1}</h3>
                <div className="row mt-1">
                  <span className="col-4 col-sm-3 text-end display-6 fs-4 text-white-50">
                    {data.item1}:
                  </span>
                  <span className="col-8 col-sm-9 display-6 fs-4 text-light ">
                    (61) 99908-7200
                  </span>
                </div>

                <div className="row mt-3">
                  <span className="col-4 col-sm-3 text-end display-6 fs-4 text-white-50">
                    {data.item2}:
                  </span>
                  <span className="col-12 col-sm-9 col-lg-10 col-xl-9 display-6 fs-4 text-light">
                    gmaintermediacao@lftv.com.br
                  </span>
                </div>

                <div className="row mt-3">
                  <span className="col-4 col-sm-3 text-end display-6 fs-4 text-white-50">
                    {data.item3}:
                  </span>
                  <span className="col-12 col-sm-9 text-center text-md-start display-6 fs-4 text-light">
                    ST SDN Conjunto Nacional Brasília, Conjunto A, SN Sala 4024
                    - 70.077-900
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 pt-2">
              {/* <div id="map" className="mb-2">
                
              </div> */}
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="mt-5 col-11 col-lg-5">
              <img src={GMABusinessLogo} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

export default Contato;
