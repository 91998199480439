import React, { useEffect, useState } from 'react';
import cubeSoy from '../media/icons/cubeSoy.svg';
import cubeRobot from '../media/icons/cubeRobot.svg';
import cubeOres from '../media/icons/cubeOres.svg';
import cubeLeather from '../media/icons/cubeLeather.svg';
import cubeAgricultural from '../media/icons/cubeAgricultural.svg';
import cubeHandShake from '../media/icons/cubeHandShake.svg';
import cubeBroking from '../media/icons/cubeBroking.svg';
import cubeFarming from '../media/icons/cubeFarming.svg';
import cubeConsultancy from '../media/icons/cubeConsultancy.svg';
import '../css/components/ListActivities.css';
import getData from '../utils/data/mainData';
import { listActivities } from '../utils/data/types';

const ListActivities: React.FC = () => {
  const [data, setData] = useState<listActivities|null>(null);

  const loadData = async () => {
    const auxData:listActivities|any = await getData({ data: 'LIST_ACTIVITIES' });
    setData(auxData);
  };

  useEffect(() => {
    loadData();
  }, []);

  if(data){

    return (
      <div className="container">
        {/* ******************************************************** Class separator *************************************************************************** */}
        <div
          id="id_commerce"
          className="mt-5 row justify-content-center justify-content-md-end align-items-center"
        >
          <div className="col-12 col-md-8 d-sm-none d-md-block parallaxAtacadista "></div>
          <div className="d-none d-md-block text-start col-md-4">
            <h3 className="display-4">{data.label1}</h3>
          </div>
          <div className="d-block d-md-none col-12 text-center mt-3">
            <h3 className="display-4">{data.label1}</h3>
          </div>
        </div>

          <div className="container col-12 col-md-9 mb-5 mt-5">
            <h6 className='text-center display-6 fs-4'>{data.description}</h6>
            {/* *********************************************************** Line Separator ******************************************************************* */}
            <div className="mt-4 row justify-content-md-evenly justify-content-center">
              <div className="row col-12 col-md-6 justify-content-center align-items-center">
                <div className="col-6 col-md-5 ">
                  <img src={cubeSoy} />
                </div>
                <div className=" col-6 col-md-6 text-center">
                  <h4 className="fs-3 fw-lighter text-decoration-underline">
                    {data.item1}
                  </h4>
                  <p className="mt-3 justifyText text-md-center">
                    {/* Atuamos no comercio de soja e algodão. */}
                  </p>
                </div>
              </div>

              <div className="row col-12 col-md-6 justify-content-center align-items-center">
                <div className=" col-6 col-md-6 text-center">
                  <h4 className="fs-3 fw-lighter text-decoration-underline">
                    {data.item2}
                  </h4>
                  <p className="mt-3 justifyText text-md-center">
                    {/* Produtos que se derivam da extração de mineral. (Não incluso combustível) */}
                  </p>
                </div>
                <div className=" col-6 col-md-5">
                  <img src={cubeOres} />
                </div>
              </div>
            </div>

            {/* *********************************************************** Line Separator ******************************************************************* */}
            <div className="mt-4 row justify-content-md-evenly justify-content-center">
              <div className="row col-12 col-md-6 justify-content-center align-items-center">
                <div className="col-6 col-md-5 ">
                  <img src={cubeLeather} />
                </div>
                <div className=" col-6 col-md-6 text-center">
                  <h4 className="fs-3 fw-lighter text-decoration-underline">
                    {data.item3}
                  </h4>
                </div>
              </div>
            {/* *********************************************************** Line Separator ******************************************************************* */}

              <div className="row col-12 col-md-6 justify-content-center align-items-center">
                <div className=" col-6 col-md-6 text-center">
                  <h4 className="fs-3 fw-lighter text-decoration-underline">
                    {data.item4}
                  </h4>
                </div>
                <div className=" col-6 col-md-5">
                  <img src={cubeRobot} />
                </div>
              </div>
              {/* <div className="row col-12 col-md-6 justify-content-center align-items-center">
                <div className=" col-6 col-md-6 text-center">
                  <h4 className="fs-3 fw-lighter text-decoration-underline">
                    {data.item4}
                  </h4>
                </div>
                <div className=" col-6 col-md-5">
                  <img src={cubeAgricultural} />
                </div>
              </div> */}
            </div>
          </div>

        {/* ******************************************************** Class separator *************************************************************************** */}
        <div
          id="id_empreendimentos"
          className="mt-4 mb-5 row justify-content-end align-items-center"
        >
          <div className="col-4 text-end d-none d-md-block">
            <h3 className="display-4 d-none d-xl-block">{data.label2}</h3>
            <h3 className="display-6 d-lg-block d-xl-none">{data.label2}</h3>
          </div>
          <div className="col-12 col-md-8 d-sm-none d-md-block parallaxEmpreendimentos"></div>
          <div className="col-12 text-center mt-3 d-block d-md-none">
            <h3 className="display-4">{data.label2}</h3>
          </div>
        </div>

        <div className="container col-12 col-md-9 mb-5 mt-5">
        <h6 className='text-center display-6 fs-4'>{data.description2}</h6>

          {/* *********************************************************** Line Separator ******************************************************************* */}
          <div className="mt-4 row justify-content-md-evenly justify-content-center">
            <div className="row col-12 col-md-6 justify-content-center align-items-center">
              <div className="col-6 col-md-5 ">
                <img src={cubeHandShake} />
              </div>
              <div className=" col-6 col-md-6 text-center">
                <h4 className="fs-3 fw-lighter text-decoration-underline">
                  {data.item21}
                </h4>
              </div>
            </div>

            <div className="row col-12 col-md-6 justify-content-center align-items-center">
              <div className=" col-6 col-md-6 text-center">
                <h4 className="fs-3 fw-lighter text-decoration-underline">
                  {data.item22}
                </h4>
                {/* <p className="mt-3 justifyText text-md-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas a interdum libero. In laoreet ac purus id fringilla.
                  Aenean id.
                </p> */}
              </div>
              <div className=" col-6 col-md-5">
                <img src={cubeHandShake} />
              </div>
            </div>
          </div>

          {/* *********************************************************** Line Separator ******************************************************************* */}
          <div className="mt-4 row justify-content-md-evenly justify-content-center">
            <div className="row col-12 col-md-6 justify-content-center align-items-center">
              <div className="col-6 col-md-5 ">
                <img src={cubeConsultancy} />
              </div>
              <div className=" col-6 col-md-6 text-center">
                <h4 className="fs-3 fw-lighter text-decoration-underline">
                  {data.item23}
                </h4>
              </div>
            </div>

            <div className="row col-12 col-md-6 justify-content-center align-items-center">
              <div className=" col-6 col-md-6 text-center">
                <h4 className="fs-3 fw-lighter text-decoration-underline">
                  {data.item24}
                </h4>
                {/* <p className="mt-3 justifyText text-md-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas a interdum libero. In laoreet ac purus id fringilla.
                  Aenean id.
                </p> */}
              </div>
              <div className=" col-6 col-md-5">
                <img src={cubeConsultancy} />
              </div>
            </div>
          </div>

          {/* *********************************************************** Line Separator ******************************************************************* */}
          <div className="mt-4 row justify-content-md-evenly justify-content-center">
            <div className="row col-12 col-md-6 justify-content-center align-items-center">
              <div className="col-6 col-md-5 ">
                <img src={cubeConsultancy} />
              </div>
              <div className=" col-6 col-md-6 text-center">
                <h4 className="fs-3 fw-lighter text-decoration-underline">
                  {data.item25}
                </h4>
                {/* <p className="mt-3 justifyText text-md-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas a interdum libero. In laoreet ac purus id fringilla.
                  Aenean id.
                </p> */}
              </div>
            </div>

            <div className="row col-12 col-md-6 justify-content-center align-items-center">
              <div className=" col-6 col-md-6 text-center">
                <h4 className="fs-3 fw-lighter text-decoration-underline">
                  {data.item26}
                </h4>
                {/* <p className="mt-3 justifyText text-md-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas a interdum libero. In laoreet ac purus id fringilla.
                  Aenean id.
                </p> */}
              </div>
              <div className=" col-6 col-md-5">
                <img src={cubeBroking} />
              </div>
            </div>
          </div>

          {/* *********************************************************** Line Separator ******************************************************************* */}
          <div className="mt-4 row justify-content-md-evenly justify-content-center">
            <div className="row col-12 col-md-6 justify-content-center align-items-center">
              <div className="col-6 col-md-5 ">
                <img src={cubeHandShake} />
              </div>
              <div className=" col-6 col-md-6 text-center">
                <h4 className="fs-3 fw-lighter text-decoration-underline">
                  {data.item27}
                </h4>
                {/* <p className="mt-3 justifyText text-md-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas a interdum libero. In laoreet ac purus id fringilla.
                  Aenean id.
                </p> */}
              </div>
            </div>

            <div className="row col-12 col-md-6 justify-content-center align-items-center">
              <div className=" col-6 col-md-6 text-center">
                <h4 className="fs-3 fw-lighter text-decoration-underline">
                  {data.item28}
                </h4>
                {/* <p className="mt-3 justifyText text-md-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas a interdum libero. In laoreet ac purus id fringilla.
                  Aenean id.
                </p> */}
              </div>
              <div className=" col-6 col-md-5">
                <img src={cubeConsultancy} />
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }else{
    return(<div/>)
  }
};

export default ListActivities;
