import { screenHome } from '../types';

export const homePtg:screenHome = {
  whatWeDo: 'O que fazemos',
  whatWeDoP1: 'Acreditamos no seu sonho e encontramos possibilidades para empresários(as) visionários(as) realizarem investimentos nos seus ramos de forma segura, alavancando suas empresas e seus retornos financeiros.',
  whatWeDoP2: 'Somos uma empresa conectada com o Brasil e o mundo.',
  whatWeDoP3:"Com isso, auxiliamos no processo de busca de novos negócios, dentro do ramo do parceiro indicado, possibilitando negociação entre as empresas.",
  whatWeDoP4:"Além disso, garantimos seriedade e profissionalismo por meio de uma equipe altamente capacitada para que a negociação seja realizada de forma célere, segura e organizada, auxiliando as empresas filiadas.",
  whatWeDolabel:'Atuação:',
  itens:{
    item1:'Busca por novos negócios no seu ramo de atividade, nacionais e internacionais.',
    item2:'Novas parcerias.',
    item3:'Estruturação documental.',
    item4:'Auxílio no levantamento de recursos perante instituições financeiras.',
    item5:'Elaboração de contratos nacionais e internacionais.',
    item6:"Assessoria em negociações nacionais e internacionais."
  },
};
export const homeEng:screenHome = {
  whatWeDo: 'What we do',
  whatWeDoP1: "We believe in your dream and we can create possibilities for visionary entrepreneurs to make investments in their fields safely, leveraging their companies and their financial returns.",
  whatWeDoP2:"We are a company connected with Brazil and the world.",
  whatWeDoP3:"With this, we assist in the process of searching for new business, within the branch of the indicated partner, enabling negotiation between companies.",
  whatWeDoP4:"In addition, we guarantee seriousness and professionalism through a highly qualified team so that the negotiation is carried out in a fast, safe and organized way, helping the affiliated companies.",
  whatWeDolabel:'Operation',
  itens:{
    item1:'Search for new business in your field of activity, national and international.',
    item2:'New partnerships.',
    item3:'Document structuring.',
    item4:'Assistance in raising funds from financial institutions.',
    item5:'Elaboration of national and international contracts.',
    item6:'Assistance in national and international negotiations.'
  },
};
