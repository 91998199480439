import React from 'react';
import '../../css/components/AnimateLogoMobile.css';

const AnimatedLogoMobile: React.FC = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        version="1.1"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        overflow="visible"
        clipRule="evenodd"
        viewBox="0 0 600 600"
      >
        <defs>
          <linearGradient
            id="id0Mobile"
            gradientUnits="userSpaceOnUse"
            x1="198.345"
            y1="174.381"
            x2="136.575"
            y2="584.286"
          >
            <stop offset="0" stopOpacity="1" stopColor="#868586" />
            <stop offset="0.133333" stopOpacity="1" stopColor="#B9B9BA" />
            <stop offset="0.490196" stopOpacity="1" stopColor="#ECEDEE" />
            <stop offset="0.811765" stopOpacity="1" stopColor="#B9B9BA" />
            <stop offset="1" stopOpacity="1" stopColor="#868586" />
          </linearGradient>
          <linearGradient
            id="id1Mobile"
            gradientUnits="userSpaceOnUse"
            x1="287.945"
            y1="9.821"
            x2="305.666"
            y2="293.788"
          >
            <stop offset="0" stopOpacity="1" stopColor="#868687" />
            <stop offset="0.74902" stopOpacity="1" stopColor="#BABABB" />
            <stop offset="1" stopOpacity="1" stopColor="#EEEFF0" />
          </linearGradient>
          <linearGradient
            id="id2Mobile"
            gradientUnits="userSpaceOnUse"
            x1="439.384"
            y1="588.079"
            x2="439.384"
            y2="165.465"
          >
            <stop offset="0" stopOpacity="1" stopColor="#818081" />
            <stop offset="0.239216" stopOpacity="1" stopColor="#B0B0B0" />
            <stop offset="0.501961" stopOpacity="1" stopColor="#E0E0E0" />
            <stop offset="0.819608" stopOpacity="1" stopColor="#B1B1B1" />
            <stop offset="1" stopOpacity="1" stopColor="#828182" />
          </linearGradient>
        </defs>
        <g id="txtBusiness">
          <metadata id="mtBusiness" />
          <polygon
            fill="url(#id0Mobile)"
            points="42.382,453.185 41.529,170.587 91.047,197.054 90.194,424.156 243.018,508.679 242.164,331.949 176.424,296.091 175.57,411.349 135.444,393.42 137.151,222.667 293.39,308.044 290.829,588.079 "
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="translate"
              from="-90 90"
              to="0 0"
              dur=".5s"
            />
          </polygon>
          <polygon
            fill="url(#id1Mobile)"
            points="310.466,293.53 53.482,154.366 105.562,123.631 258.386,133.022 248.141,38.254 292.537,10.079 540.129,156.927 498.294,181.686 293.39,63.867 312.173,170.587 158.495,159.489 356.57,265.355 "
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="translate"
              from="0 -150"
              to="0 0"
              dur=".5s"
            />
          </polygon>
          <path
            fill="url(#id2Mobile)"
            d="M517.078 249.987l0 52.081 -157.094 93.914 0 -54.641 157.094 -91.354zm-157.094 311.626l-47.811 26.466 0 -275.766 253.569 -146.848 0.854 274.913 -49.518 27.32 0.853 -107.574 -158.8 92.206 0.853 109.283z"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="translate"
              from="90 90"
              to="0 0"
              dur=".5s"
            />
          </path>
        </g>
      </svg>
      <div className="col-12 text-center">
        <svg
          version="1.1"
          fillRule="evenodd"
          clipRule="evenodd"
          viewBox="0 0 350 75"
          overflow="visible"
        >
          <g id="textMobile" aria-label="BUSINESS">
            <path
              id="_BM"
              d="m16.017 54.21v-47.123h17.679q5.4002 0 8.6467 1.4465 3.2787 1.4143 5.1109 4.4037 1.8644 2.9573 1.8644 6.2038 0 3.0215-1.6393 5.6895-1.6393 2.668-4.9502 4.3073 4.2752 1.2536 6.5574 4.2752 2.3144 3.0215 2.3144 7.136 0 3.3108-1.4143 6.1717-1.3822 2.8287-3.4394 4.3716t-5.1752 2.3465q-3.0858 0.77146-7.586 0.77146zm6.2359-27.322h10.19q4.1466 0 5.9466-0.54645 2.3787-0.70717 3.568-2.3465 1.2215-1.6393 1.2215-4.1144 0-2.3465-1.125-4.1144-1.125-1.8001-3.2144-2.4429-2.0894-0.67502-7.1681-0.67502h-9.4182zm0 21.762h11.733q3.0215 0 4.243-0.22501 2.1537-0.38573 3.6001-1.2858 1.4465-0.90003 2.3787-2.6037 0.93218-1.7358 0.93218-3.9859 0-2.6358-1.35-4.5645-1.35-1.9608-3.7609-2.7322-2.3787-0.8036-6.8788-0.8036h-10.897z"
            />
            <path
              id="_UM"
              d="m91.105 7.0868h6.2359v27.226q0 7.1038-1.6072 11.283-1.6072 4.1787-5.8181 6.8145-4.1787 2.6037-10.993 2.6037-6.6217 0-10.833-2.2822t-6.0109-6.5895q-1.8001-4.3394-1.8001-11.829v-27.226h6.2359v27.194q0 6.1395 1.125 9.0646 1.1572 2.893 3.9216 4.468 2.7965 1.5751 6.8145 1.5751 6.8788 0 9.8039-3.118 2.9251-3.118 2.9251-11.99z"
            />
            <path
              id="_SM"
              d="m105.6 39.07 5.8824-0.5143q0.41787 3.5358 1.9286 5.8181 1.5429 2.2501 4.7573 3.6644 3.2144 1.3822 7.2324 1.3822 3.568 0 6.3002-1.0608 2.7322-1.0608 4.0502-2.893 1.35-1.8644 1.35-4.0501 0-2.2179-1.2858-3.8573-1.2858-1.6715-4.243-2.7965-1.8965-0.73931-8.3896-2.2822-6.4931-1.5751-9.0968-2.9573-3.3751-1.7679-5.0466-4.3716-1.6394-2.6358-1.6394-5.8824 0-3.568 2.0251-6.6538 2.0251-3.118 5.9145-4.7252 3.8894-1.6072 8.6468-1.6072 5.2395 0 9.2253 1.7036 4.018 1.6715 6.1717 4.9502 2.1536 3.2787 2.3144 7.4253l-5.9788 0.45002q-0.48216-4.468-3.2787-6.7502-2.7644-2.2822-8.1967-2.2822-5.6574 0-8.261 2.0894-2.5715 2.0572-2.5715 4.9823 0 2.5394 1.8322 4.1787 1.8001 1.6393 9.3861 3.3751 7.6181 1.7036 10.447 2.9894 4.1144 1.8965 6.0752 4.8216 1.9608 2.893 1.9608 6.686 0 3.7609-2.1536 7.1038-2.1537 3.3108-6.2038 5.1752-4.018 1.8322-9.0646 1.8322-6.3967 0-10.736-1.8644-4.3073-1.8644-6.7824-5.5931-2.443-3.7609-2.5715-8.486z"
            />
            <path id="_IM" d="m152.69 54.21v-47.123h6.2359v47.123z" />
            <path
              id="_NM"
              d="m169.86 54.21v-47.123h6.3967l24.751 36.998v-36.998h5.9788v47.123h-6.3967l-24.751-37.03v37.03z"
            />
            <path
              id="_EM"
              d="m217.59 54.21v-47.123h34.073v5.5609h-27.837v14.433h26.069v5.5288h-26.069v16.04h28.93v5.5609z"
            />
            <path
              id="_S2M"
              d="m259.25 39.07 5.8824-0.5143q0.41788 3.5358 1.9286 5.8181 1.5429 2.2501 4.7573 3.6644 3.2144 1.3822 7.2324 1.3822 3.568 0 6.3002-1.0608 2.7322-1.0608 4.0502-2.893 1.35-1.8644 1.35-4.0501 0-2.2179-1.2858-3.8573-1.2858-1.6715-4.243-2.7965-1.8965-0.73931-8.3896-2.2822-6.4931-1.5751-9.0968-2.9573-3.3751-1.7679-5.0466-4.3716-1.6394-2.6358-1.6394-5.8824 0-3.568 2.0251-6.6538 2.0251-3.118 5.9145-4.7252 3.8894-1.6072 8.6468-1.6072 5.2395 0 9.2253 1.7036 4.018 1.6715 6.1716 4.9502 2.1537 3.2787 2.3144 7.4253l-5.9788 0.45002q-0.48216-4.468-3.2787-6.7502-2.7644-2.2822-8.1967-2.2822-5.6574 0-8.261 2.0894-2.5715 2.0572-2.5715 4.9823 0 2.5394 1.8322 4.1787 1.8001 1.6393 9.3861 3.3751 7.6181 1.7036 10.447 2.9894 4.1144 1.8965 6.0752 4.8216 1.9608 2.893 1.9608 6.686 0 3.7609-2.1536 7.1038-2.1536 3.3108-6.2038 5.1752-4.018 1.8322-9.0646 1.8322-6.3967 0-10.736-1.8644-4.3073-1.8644-6.7824-5.5931-2.443-3.7609-2.5715-8.486z"
            />
            <path
              id="_S3M"
              d="m303.16 39.07 5.8824-0.5143q0.41787 3.5358 1.9286 5.8181 1.5429 2.2501 4.7573 3.6644 3.2144 1.3822 7.2324 1.3822 3.568 0 6.3002-1.0608 2.7322-1.0608 4.0502-2.893 1.35-1.8644 1.35-4.0501 0-2.2179-1.2858-3.8573-1.2858-1.6715-4.243-2.7965-1.8965-0.73931-8.3896-2.2822-6.4931-1.5751-9.0968-2.9573-3.3751-1.7679-5.0466-4.3716-1.6393-2.6358-1.6393-5.8824 0-3.568 2.0251-6.6538 2.0251-3.118 5.9145-4.7252 3.8894-1.6072 8.6468-1.6072 5.2395 0 9.2253 1.7036 4.018 1.6715 6.1716 4.9502 2.1536 3.2787 2.3144 7.4253l-5.9788 0.45002q-0.48216-4.468-3.2787-6.7502-2.7644-2.2822-8.1967-2.2822-5.6574 0-8.261 2.0894-2.5715 2.0572-2.5715 4.9823 0 2.5394 1.8322 4.1787 1.8001 1.6393 9.3861 3.3751 7.6181 1.7036 10.447 2.9894 4.1144 1.8965 6.0752 4.8216 1.9608 2.893 1.9608 6.686 0 3.7609-2.1536 7.1038-2.1536 3.3108-6.2038 5.1752-4.018 1.8322-9.0646 1.8322-6.3967 0-10.736-1.8644-4.3073-1.8644-6.7824-5.5931-2.443-3.7609-2.5715-8.486z"
            />
            <animate
              attributeName="fill"
              attributeType="CSS"
              from="#000000"
              to="#E0E1E0"
              begin="1s"
              dur="1s"
              fill="freeze"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default AnimatedLogoMobile;
