import { listActivities, quemSomos, contact, navbar } from '../types';

export const listActivitesPtg:listActivities = {
  label1:"Comércio",
  description:"Oferecemos serviços para quem tem interesse em expandir seus negócios, com atuação em diversas frentes exemplificadas a seguir:",
  item1:"Agricultura",
  item2:"Mineração",
  item3:"Construção",
  item4:"Tecnologia & Indústria",
  label2:"Empreendimentos",
  description2:"Para quem busca uma estruturação, atuamos também nas seguintes áreas:",
  item21:"Holdings",
  item22:"Intermediação",
  item23:"Assessoria documental",
  item24:"Assessoria técnica",
  item25:"Pesquisa de Campo",
  item26:"Corretagem",
  item27:"Agenciamento de profissionais",
  item28:"Consultoria",
}
export const listActivitesEng:listActivities = {
  label1:"Commerce",
  description:"We offer services for those interested in expanding their business, working on several fronts, as exemplified below:",
  item1:"Agriculture",
  item2:"Mining",
  item3:"Construction",
  item4:"Technology & Industry",
  label2:"Business",
  description2:"For those looking for a structure, we also work in the following areas:",
  item21:"Holdings",
  item22:"Intermediation",
  item23:"Documentary assistance",
  item24:"Technical assistance",
  item25:"Field research",
  item26:"Broking",
  item27:"Agency of professionals",
  item28:"Consultancy",
}

export const quemSomosPtg:quemSomos = {
  label1:"Quem Somos",
  p1:"A GMA Business é uma empresa que nasceu a partir do núcleo familiar e foi constituída com o interesse de equilibrar a relação necessidade-possibilidade.",
  p2:"Fundada formalmente em 2022, mas com atuação desde o início do século XX, a GMA surgiu para interligar as possibilidades de negócios e parceiros nacionais e internacionais.",
  p3:"A GMA mantém diversos contatos profissionais para mediar e dirimir negócios no Brasil e ao redor do mundo.",
  p4:"O seu foco principal é promover e facilitar o comércio nacional e internacional em larga escala.",
  p5:"Garantimos que, com o auxílio de uma equipe altamente qualificada, sejam realizadas negociações de forma prática e segura, com profissionais capacitados para a  análise documental, com estudos para identificar a forma mais eficiente de adequação dos contratos.",
  p6:"Nossa sede está localizada em Brasília/Brasil, com filiais espalhadas pelo território nacional e com extensão de atuação em outros países."
}
export const quemSomosEng:quemSomos = {
  label1:"Who we are",
  p1:"GMA Business is a company that was born from a family nucleus and was created with the interest of balancing the need-possibility relationship.",
  p2:"Formally founded in 2022, but operating since the beginning of the 20th century, GMA emerged to connect the possibilities of national and international business and partners.",
  p3:"GMA maintains several professional contacts to mediate and settle business in Brazil and around the world.",
  p4:"Its main focus is to promote and facilitate large-scale national and international trade.",
  p5: "We guarantee that, with the help of a highly qualified team, negotiations are carried out in a practical and safe way, with professionals trained in document analysis, with studies to identify the most efficient way of adapting contracts.",
  p6: "Our headquarters are located in Brasilia/Brazil, with branches spread across the national territory and with an extension of operations in other countries."
}

export const contactPtg:contact = {
  label1:"Contato",
  item1:"Telefone",
  item2:"Email",
  item3:"Endereço"
}
export const contactEng:contact = {
  label1:"Contact",
  item1:"Phone",
  item2:"Email",
  item3:"Address"
}

export const navbarPtg:navbar = {
  label1:"Língua",
  lang1:"Português",
  lang2:"Inglês",
  item1:"O que fazemos",
  item2:"Comércio",
  item3:"Empreendimentos",
  item4:"Quem Somos",
  item5:"Contato"
}
export const navbarEng:navbar = {
  label1:"Language",
  lang1:"Portuguese",
  lang2:"English",
  item1:"What we do",
  item2:"Commerce",
  item3:"Business",
  item4:"Who we are",
  item5:"Contact"
}
