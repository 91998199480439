import { getLanguage } from '../language/definition';
import { homeEng, homePtg } from './screens/home';
import { listActivitesEng, listActivitesPtg, quemSomosEng, quemSomosPtg, contactEng, contactPtg, navbarEng, navbarPtg } from './Components/components';
import { dataToGet } from './types';

const getData = async ({ data }:dataToGet) => {
  const lang = await getLanguage();

  if (data === 'HOME' && lang === 'ptg') {
    return homePtg;
  }else if(data==="HOME"){
    return homeEng;
  }

  if (data === 'LIST_ACTIVITIES' && lang === 'ptg') {
    return listActivitesPtg;
  }else if(data==="LIST_ACTIVITIES"){
    return listActivitesEng;
  }

  if (data === 'WHO_WE_ARE' && lang === 'ptg') {
    return quemSomosPtg;
  }else if(data==="WHO_WE_ARE"){
    return quemSomosEng;
  }

  if (data === 'CONTACT' && lang === 'ptg') {
    return contactPtg;
  }else if(data==="CONTACT"){
    return contactEng;
  }

  if (data === 'NAVBAR' && lang === 'ptg') {
    return navbarPtg;
  }else if(data==="NAVBAR"){
    return navbarEng;
  }

};

export default getData;
