const LANGUAGE = 'LANG';

export const getLanguage = () => {
  const storage = localStorage.getItem(LANGUAGE);

  if (storage) {
    return storage;
  }

  localStorage.setItem(LANGUAGE, 'ptg');
  return localStorage.getItem(LANGUAGE);
};

interface props{
    lang: string
}

export const setLanguage = ({ lang }:props) => {
  localStorage.setItem(LANGUAGE, lang);
  window.location.reload();
};
