// import React from 'react';
// import {
//   BrowserRouter as Router,
//   Route
// } from "react-router-dom";
// import Home from './screens/Home';
// import './App.css';

// function App() {
//   return (
    
//     <Router>
//      <React.Suspense fallback={<div />}></React.Suspense>
//         <Route path='/' element={<Home/>}>
          
//         </Route>
//     </Router>
//   );
// }

// export default App;

import React from 'react';
import Home from './screens/Home';

const App: React.FC = () => {
  return (
        <div>
          <Home/>
        </div>
    );
}

export default App;

