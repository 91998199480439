import React from "react";
import "../../css/components/AnimateLogo.css";

const AnimatedLogo: React.FC = () => {
  return (
    <div>
      <svg
        version="1.1"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 728 250"
        overflow="visible"
        id="logo"
      >
        <defs>
          <linearGradient
            id="id0"
            gradientUnits="userSpaceOnUse"
            x1="70.341"
            y1="76.518"
            x2="45.035"
            y2="244.446"
          >
            <stop offset="0" stopOpacity="1" stopColor="#868586" />
            <stop offset="0.133333" stopOpacity="1" stopColor="#B9B9BA" />
            <stop offset="0.490196" stopOpacity="1" stopColor="#ECEDEE" />
            <stop offset="0.811765" stopOpacity="1" stopColor="#B9B9BA" />
            <stop offset="1" stopOpacity="1" stopColor="#868586" />
          </linearGradient>
          <linearGradient
            id="id1"
            gradientUnits="userSpaceOnUse"
            x1="107.048"
            y1="9.102"
            x2="114.308"
            y2="125.436"
          >
            <stop offset="0" stopOpacity="1" stopColor="#868687" />
            <stop offset="0.74902" stopOpacity="1" stopColor="#BABABB" />
            <stop offset="1" stopOpacity="1" stopColor="#EEEFF0" />
          </linearGradient>
          <linearGradient
            id="id2"
            gradientUnits="userSpaceOnUse"
            x1="169.089"
            y1="246"
            x2="169.089"
            y2="72.865"
          >
            <stop offset="0" stopOpacity="1" stopColor="#818081" />
            <stop offset="0.239216" stopOpacity="1" stopColor="#B0B0B0" />
            <stop offset="0.501961" stopOpacity="1" stopColor="#E0E0E0" />
            <stop offset="0.819608" stopOpacity="1" stopColor="#B1B1B1" />
            <stop offset="1" stopOpacity="1" stopColor="#828182" />
          </linearGradient>
        </defs>
        <g>
          {/* <g id="text" fill="#e0e1e0" aria-label="BUSINESS"> */}
          <g id="text" aria-label="BUSINESS">
            <path
              id="_B"
              d="m237.02 159.82v-70.16h26.322q8.0401 0 12.874 2.1536 4.8815 2.1057 7.6094 6.5565 2.7758 4.4029 2.7758 9.2366 0 4.4986-2.4408 8.4709-2.4408 3.9722-7.3701 6.413 6.3651 1.8664 9.763 6.3651 3.4458 4.4986 3.4458 10.624 0 4.9294-2.1057 9.1887-2.0579 4.2115-5.1208 6.5087t-7.7051 3.4936q-4.5944 1.1486-11.294 1.1486zm9.2844-40.679h15.171q6.1737 0 8.8537-0.81358 3.5415-1.0529 5.3122-3.4936 1.8186-2.4408 1.8186-6.1258 0-3.4936-1.675-6.1258-1.675-2.68-4.7858-3.6372-3.1108-1.005-10.672-1.005h-14.022zm0 32.4h17.468q4.4986 0 6.3172-0.33501 3.2065-0.57429 5.3601-1.9143 2.1536-1.34 3.5415-3.8765 1.3879-2.5843 1.3879-5.9344 0-3.9244-2.01-6.7958-2.01-2.9193-5.5994-4.0679-3.5415-1.1964-10.242-1.1964h-16.224z"
            />
            <path
              id="_U"
              d="m348.82 89.664h9.2844v40.536q0 10.577-2.3929 16.798-2.3929 6.2215-8.6623 10.146-6.2215 3.8765-16.367 3.8765-9.8587 0-16.128-3.3979-6.2694-3.3979-8.9494-9.8109-2.68-6.4608-2.68-17.612v-40.536h9.2844v40.488q0 9.1409 1.675 13.496 1.7229 4.3072 5.8387 6.6522 4.1636 2.345 10.146 2.345 10.242 0 14.597-4.6422t4.3551-17.851z"
            />
            <path
              id="_S"
              d="m370.4 137.28 8.758-0.76573q0.62215 5.2644 2.8715 8.6623 2.2972 3.3501 7.083 5.4558 4.7858 2.0579 10.768 2.0579 5.3122 0 9.3802-1.5793 4.0679-1.5793 6.0301-4.3072 2.01-2.7758 2.01-6.0301 0-3.3022-1.9143-5.743-1.9143-2.4886-6.3172-4.1636-2.8236-1.1007-12.491-3.3979-9.6673-2.345-13.544-4.4029-5.0251-2.6322-7.5137-6.5087-2.4408-3.9243-2.4408-8.758 0-5.3122 3.015-9.9066 3.015-4.6422 8.8059-7.0351 5.7908-2.3929 12.874-2.3929 7.8008 0 13.735 2.5365 5.9822 2.4886 9.1887 7.3701 3.2065 4.8815 3.4458 11.055l-8.9016 0.67001q-0.71787-6.6522-4.8815-10.05-4.1158-3.3979-12.204-3.3979-8.423 0-12.299 3.1108-3.8286 3.0629-3.8286 7.418 0 3.7808 2.7279 6.2215 2.68 2.4408 13.975 5.0251 11.342 2.5365 15.554 4.4508 6.1258 2.8236 9.0452 7.1787 2.9193 4.3072 2.9193 9.9544 0 5.5994-3.2065 10.577-3.2065 4.9294-9.2366 7.7051-5.9822 2.7279-13.496 2.7279-9.5237 0-15.985-2.7758-6.413-2.7758-10.098-8.3273-3.6372-5.5994-3.8286-12.634z"
            />
            <path id="_I" d="m440.52 159.82v-70.16h9.2844v70.16z" />
            <path
              id="_N"
              d="m466.07 159.82v-70.16h9.5237l36.851 55.084v-55.084h8.9016v70.16h-9.5237l-36.851-55.132v55.132z"
            />
            <path
              id="_E"
              d="m537.14 159.82v-70.16h50.729v8.2794h-41.445v21.488h38.813v8.2316h-38.813v23.881h43.072v8.2794z"
            />
            <path
              id="_S2"
              d="m599.16 137.28 8.758-0.76573q0.62215 5.2644 2.8715 8.6623 2.2972 3.3501 7.083 5.4558 4.7858 2.0579 10.768 2.0579 5.3122 0 9.3802-1.5793 4.0679-1.5793 6.0301-4.3072 2.01-2.7758 2.01-6.0301 0-3.3022-1.9143-5.743-1.9143-2.4886-6.3172-4.1636-2.8236-1.1007-12.491-3.3979-9.6673-2.345-13.544-4.4029-5.0251-2.6322-7.5137-6.5087-2.4408-3.9243-2.4408-8.758 0-5.3122 3.015-9.9066 3.015-4.6422 8.8058-7.0351 5.7908-2.3929 12.874-2.3929 7.8008 0 13.735 2.5365 5.9822 2.4886 9.1887 7.3701 3.2065 4.8815 3.4458 11.055l-8.9016 0.67001q-0.71787-6.6522-4.8815-10.05-4.1158-3.3979-12.204-3.3979-8.423 0-12.299 3.1108-3.8286 3.0629-3.8286 7.418 0 3.7808 2.7279 6.2215 2.68 2.4408 13.975 5.0251 11.342 2.5365 15.554 4.4508 6.1258 2.8236 9.0451 7.1787 2.9193 4.3072 2.9193 9.9544 0 5.5994-3.2065 10.577-3.2065 4.9294-9.2366 7.7051-5.9822 2.7279-13.496 2.7279-9.5237 0-15.985-2.7758-6.413-2.7758-10.098-8.3273-3.6372-5.5994-3.8286-12.634z"
            />
            <path
              id="_S3"
              d="m664.54 137.28 8.758-0.76573q0.62216 5.2644 2.8715 8.6623 2.2972 3.3501 7.083 5.4558 4.7858 2.0579 10.768 2.0579 5.3122 0 9.3802-1.5793 4.0679-1.5793 6.0301-4.3072 2.01-2.7758 2.01-6.0301 0-3.3022-1.9143-5.743-1.9143-2.4886-6.3172-4.1636-2.8236-1.1007-12.491-3.3979-9.6673-2.345-13.544-4.4029-5.0251-2.6322-7.5137-6.5087-2.4408-3.9243-2.4408-8.758 0-5.3122 3.015-9.9066 3.015-4.6422 8.8058-7.0351 5.7908-2.3929 12.874-2.3929 7.8008 0 13.735 2.5365 5.9822 2.4886 9.1887 7.3701 3.2065 4.8815 3.4458 11.055l-8.9016 0.67001q-0.71787-6.6522-4.8815-10.05-4.1158-3.3979-12.204-3.3979-8.423 0-12.299 3.1108-3.8286 3.0629-3.8286 7.418 0 3.7808 2.7279 6.2215 2.68 2.4408 13.975 5.0251 11.342 2.5365 15.554 4.4508 6.1258 2.8236 9.0451 7.1787 2.9193 4.3072 2.9193 9.9544 0 5.5994-3.2065 10.577-3.2065 4.9294-9.2366 7.7051-5.9822 2.7279-13.496 2.7279-9.5237 0-15.985-2.7758-6.413-2.7758-10.098-8.3273-3.6372-5.5994-3.8286-12.634z"
            />
          </g>

          <animate
            attributeName="fill"
            attributeType="CSS"
            from="#000000"
            to="#E0E1E0"
            begin="1s"
            dur="1s"
            fill="freeze"
          />

          <polygon
            id="svgG"
            fill="url(#id0)"
            points="6.447,190.737 6.098,74.964 26.384,85.807 26.034,178.845 88.642,213.472 88.292,141.07 61.361,126.38 61.011,173.598 44.572,166.253 45.271,96.3 109.279,131.277 108.229,246 "
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="translate"
              from="-60 60"
              to="0 0"
              dur=".5s"
            />
          </polygon>
          <polygon
            fill="url(#id1)"
            points="116.274,125.33 10.994,68.319 32.33,55.727 94.938,59.574 90.741,20.75 108.929,9.208 210.361,69.368 193.223,79.511 109.279,31.243 116.974,74.964 54.016,70.417 135.162,113.788 "
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="translate"
              from="0 -120"
              to="0 0"
              dur=".5s"
            />
          </polygon>
          <path
            fill="url(#id2)"
            d="M200.918 107.492l0 21.336 -64.358 38.474 0 -22.384 64.358 -37.426zm-64.358 127.666l-19.586 10.842 0 -112.975 103.881 -60.16 0.349 112.626 -20.286 11.192 0.349 -44.071 -65.056 37.775 0.349 44.771z"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="translate"
              from="60 60"
              to="0 0"
              dur=".5s"
            />
          </path>
        </g>
      </svg>
    </div>
  );
};

export default AnimatedLogo;
