import React, { useEffect, useState } from 'react';
import '../css/home.css';
import ListActivities from '../components/ListActivities';
import QuemSomos from '../components/QuemSomos';
import Contato from '../components/Contato';
import NavBar from '../components/NavBar';
import AnimatedLogo from '../components/animations/AnimatedLogo';
import AnimatedLogoMobile from '../components/animations/AnimatedLogoMobile';
import getData from '../utils/data/mainData';
import partnership from '../media/background/parceria.png'
import { screenHome } from '../utils/data/types';

const Home:React.FC = () => {
  const [data, setData] = useState<screenHome|null>(null);

  const loadData = async () => {
    const auxData:screenHome|any = await getData({ data: 'HOME' });
    setData(auxData);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (data) {
    return(
      <div className="overflow-hidden">
        <div
          id="mainLogo"
          className="row align-items-center justify-content-center"
        >
          <div className="row col-xl-5 d-none d-xl-block">
            <AnimatedLogo />
          </div>
          <div className="col-9 col-sm-3 col-md-5 d-block d-xl-none">
            <div className="row">
              <AnimatedLogoMobile />
            </div>
          </div>
          <NavBar />
        </div>
        <div className="container mt-5 pt-5">
          <QuemSomos />
        </div>
        <div className="container">
          <h3 id="inicio_id" className="display-4 text-center mt-5 pt-5">
            {data.whatWeDo}
          </h3>
          <div
            className="row col-12  align-items-center justify-content-center"
          >
            <div className="row col-11 col-lg-12 align-items-center justify-content-center">
              <p className="mt-4 text-center customFont">
                {data.whatWeDoP1}
              </p>
              <p className="mt-4 text-center customFont">
                {data.whatWeDoP2}
              </p>
              <p className="mt-4 text-center customFont">
                {data.whatWeDoP3}
              </p>
              <p className="mt-4 text-center customFont">
                {data.whatWeDoP4}
              </p>
              <div className='col-12 col-md-9 maxWidth'>
                <img src={partnership} className="imgFullWidth maxWidth" alt="parceria"/>
              </div>
              <ul>
                <h5 className='display-6 fs-3 text-center'>{data.whatWeDolabel}</h5>
                <div className='row justify-content-md-center'>
                  <div className='col-12 col-md-6 customFont'>
                    <br/>
                    <li>{data.itens.item1}</li>
                    <li>{data.itens.item2}</li>
                    <li>{data.itens.item3}</li>
                    <li>{data.itens.item4}</li>
                    <li>{data.itens.item5}</li>
                    <li>{data.itens.item6}</li>
                  </div>
                </div>
              </ul>
              
            </div>
          </div>
        </div>
        <div className="row">
          <ListActivities />
        </div>
        <div className="mt-5">
          <Contato />
        </div>
      </div>
    )
  }
  return (
    <div className="overflow-hidden">
      <div
        id="mainLogo"
        className="row align-items-center justify-content-center"
      >
        <div className="row col-xl-5 d-none d-xl-block">
          <AnimatedLogo />
        </div>
        <div className="col-9 col-md-5 d-block d-xl-none">
          <div className="row">
            <AnimatedLogoMobile />
          </div>
        </div>
      </div>

    </div>
  );
};
export default Home;
